import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import hasFormRole from '../../utils/FormPermissionUtils';
import { ClientFormStatus, formStatusKeys, FormStatusTag } from '../../models/ClientFormStatus';
import { ClientFormUser } from '../../models/ClientFormUser';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import ClientFormService from '../../services/ClientFormService';
import FormOwnership from '../ownership/FormOwnership';
import { Trans, useTranslation } from 'react-i18next';
import ProgressBar from '../shared/ProgressBar';
import { ClientForm, ClientFormSectionInfo } from '../../models/ClientForm';
import FormUtils from '../../utils/FormUtils';
import { FormType } from '../../models/FormTypes';
import EyeIcon from '../shared/icon/EyeIcon';
import { useNavigate } from 'react-router-dom';
import ShareIcon from '../shared/icon/ShareIcon';
import FormShareModal from '../form/FormShareModal';
import ContextMenu, { ContextMenuItem } from '../shared/ContextMenu';
import FormSubmit from '../form/FormSubmit';
import EditIcon from '../shared/icon/EditIcon';
import DeleteIcon from '../shared/icon/DeleteIcon';
import RiskUtils, { RiskRating } from '../../utils/RiskUtils';
import Badge from '../shared/badge/Badge';
import Tooltip from '../shared/Tooltip';
import { currentFormUsersAtom, currentFormVersionStateAtom } from '../../recoil/atoms/Forms';
import { FormSection, PeriodicReviewChecklistItemInstance, Progress, StepInfoResponse } from '../../models/Form';
import InlineEditor from '../shared/form-control/InlineEditor';
import usePermissions from '../../hooks/permissions/usePermissions';
import useOnlyHasPermission from '../../hooks/permissions/useOnlyHasPermission';
import DueDatePicker from '../shared/DueDatePicker';
import useFormUsers from '../../hooks/useFormUsers';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import StandardModal from '../shared/modal/variants/StandardModal';
import FormInfoModal from '../form/FormInfoModal';
import MultiCheckboxIcon from '../shared/icon/MultiCheckboxIcon';
import Button, { ButtonType } from '../shared/form-control/Button';
import PeriodicChecklistViewer from '../form/PeriodicChecklistViewer';
import { PeriodicReviewStatus } from '../../models/PeriodicReview';
import User from '../../models/User';
import LanguageUtils from '../../utils/LanguageUtils';
import ModuleService from '../../services/ModuleService';
import ReplaceDocumentWizard, { ReplaceDocumentWizardStep, replaceDocumentWizardSteps } from '../documents/replace-document/ReplaceDocumentWizard';
import { DocumentResponse } from '../../models/Document';
import RefreshIcon from '../shared/icon/RefreshIcon';
import useFormTypeText from '../../hooks/useFormTypeText';
import { Access } from '../../models/Access';
import { Associations } from '../../models/Association';
import StringUtils from '../../utils/StringUtils';
import VersionStateLabel from '../shared/version-timeline/VersionStateLabel';
import DownloadIcon from '../shared/icon/DownloadIcon';
import EffectiveDatePicker from '../shared/EffectiveDatePicker';
import DateUtils from '../../utils/DateUtils';
import DataExportService from '../../services/DataExportService';
import useStatusText from '../../hooks/useStatusText';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import DistributeHandsIcon from '../shared/icon/DistributeHandsIcon';
import DistributionWizard from '../distribution/DistributionWizard';
import DistributionManageModal from '../distribution/DistrubtionManageModal';
import { DistributionMemberStatus, DistributionStatus } from '../../models/Distribution';
import AcknowledgeButton from '../form/AcknowledgeButton';
import MailIcon from '../shared/icon/MailIcon';
import UncontrolledCopyModal from '../distribution/UncontrolledCopyModal';
import { useDistributionPermissions } from '../../hooks/permissions/useDistributionPermissions';

type FormHeaderProps = {
  formProgress: Progress;
  clientForm: ClientForm | DocumentResponse;
  formStatus: ClientFormStatus;
  startDate: Date | null;
  editMode: boolean;
  hideMainFormActions?: boolean;
  allSteps: FormSection[];
  allStepInfos: ClientFormSectionInfo[];
  onStatusChanged: () => void;
  onSetDueDate: (date: Date | null) => void;
  onSetEffectiveDate: (date: Date | null) => void;
  onCurrentUserUpdate?: (user: ClientFormUser | null, reloadForm?: boolean) => void;
  onUsersChange?: () => void;
  onRename: (newSubtitle: string) => void;
  onChecklistChanged: (checklist: PeriodicReviewChecklistItemInstance[]) => void;
  currentStep: StepInfoResponse;
  associations: Associations[];
  onFileReplaced?: () => void;
  onAccessTypeChange?: (value: Access) => void;
  scrolledToBottom: boolean;
};

const FormHeader: FC<FormHeaderProps> = (props) => {
  const {
    onSetDueDate,
    onUsersChange,
    onCurrentUserUpdate,
    startDate,
    clientForm,
    formStatus,
    formProgress,
    onStatusChanged,
    editMode,
    onRename,
    hideMainFormActions,
    allSteps,
    allStepInfos,
    onChecklistChanged,
    associations,
    onFileReplaced,
    onAccessTypeChange,
    onSetEffectiveDate,
    scrolledToBottom,
  } = props;
  const user = useRecoilValue(currentUserAtom);
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const title = useMemo(() => FormUtils.getFormTitle(clientForm), [clientForm]);
  const [subtitle, setSubtitle] = useState<string | null>(null);
  const [showDistributionModal, setShowDistributionModal] = useState<'create' | 'manage' | false>(false);
  const [showUncontrolledCopyModal, setShowUncontrolledCopyModal] = useState(false);
  const readOnly = useMemo(() => !!clientForm.originId || !!clientForm.archivedUtc, [clientForm.archivedUtc, clientForm.originId]);
  const { canCreateDistribution, canViewDistribution, canSendUncontrolledCopy, canMaintainDistribution } = useDistributionPermissions(
    clientForm.distribution,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (clientForm.form.type === FormType.Document) {
        const module = await ModuleService.getModuleInfo(clientForm.clientModuleId);
        setSubtitle(LanguageUtils.getTranslation('name', module.data.translations));
      } else {
        setSubtitle(title ? FormUtils.getFormTemplateTitle(clientForm) : '');
      }
    };

    fetchData();
  }, [clientForm, title]);

  const [currentFormUser, setCurrentFormUser] = useState<ClientFormUser | null>(null);
  const [formUsers, setFormUsers] = useRecoilState(currentFormUsersAtom);
  const [showFormInfoModal, setShowFormInfoModal] = useState(false);
  const [showFormShareModal, setShowFormShareModal] = useState(false);
  const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false);
  const [expandedView, setExpandedView] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const hasPermission = usePermissions();
  const onlyHasPermission = useOnlyHasPermission();
  const refetchUsers = useFormUsers();
  const [showReplaceDocumentModal, setShowReplaceDocumentModal] = useState(false);
  const [activeReplaceWizardStep, setActiveReplaceWizardStep] = useState<ReplaceDocumentWizardStep>(replaceDocumentWizardSteps[0]);

  const { t } = useTranslation(['form', 'common', 'documents', 'risk', 'distribution']);
  const navigate = useNavigate();
  const prefix = useMemo(() => {
    return clientForm.type === FormType.Document
      ? `${StringUtils.makePrefixWithNumber(
          (clientForm as DocumentResponse).prefix,
          (clientForm as DocumentResponse).documentNumber,
          (clientForm as DocumentResponse).templateModuleTranslations,
        )}-`
      : '';
  }, [clientForm]);

  useEffect(() => {
    if (user) {
      const formUser = ClientFormService.getCurrentFormUser(formUsers, user);
      setCurrentFormUser(formUser);
      onCurrentUserUpdate && onCurrentUserUpdate(formUser);
    }
  }, [user, formUsers, onCurrentUserUpdate]);

  const onFormUsersChange = (changedUsers: ClientFormUser[]) => {
    setFormUsers([...changedUsers].sort((a, b) => (a.firstName && b.firstName ? (a.firstName > b.firstName ? 1 : -1) : -1)));
    onUsersChange && onUsersChange();
    if (user) {
      const formUser = ClientFormService.getCurrentFormUser(changedUsers, user);
      if (formUser !== currentFormUser) {
        setCurrentFormUser(formUser);
        onCurrentUserUpdate && onCurrentUserUpdate(formUser, true);
      }
    }
  };

  const onFormDueDateChange = (date: Date | null) => {
    ClientFormService.updateForm(
      clientForm.id,
      clientForm.accessType,
      date,
      (clientForm.effectiveDateUtc && new Date(clientForm.effectiveDateUtc)) || null,
      title,
      clientForm.clientModuleId,
      clientForm.clientModuleSectionId,
    ).then(() => {
      onSetDueDate(date);
    });
  };

  const onEffectiveDateChanged = useCallback(
    (effectiveDate: Date | null) => {
      ClientFormService.updateForm(
        clientForm.id,
        clientForm.accessType,
        clientForm.dueDateUtc === null ? null : new Date(clientForm.dueDateUtc),
        effectiveDate,
        title,
        clientForm.clientModuleId,
        clientForm.clientModuleSectionId,
      ).then(() => {
        onSetEffectiveDate(effectiveDate);
      });
    },
    [
      clientForm.accessType,
      clientForm.clientModuleId,
      clientForm.clientModuleSectionId,
      clientForm.dueDateUtc,
      clientForm.id,
      onSetEffectiveDate,
      title,
    ],
  );

  const onCurrentUserRoleChange = (formUser: ClientFormUser) => {
    setCurrentFormUser((prevUser) => {
      return { ...(prevUser as User), role: formUser.role };
    });
  };

  useEffect(() => {
    refetchUsers(clientForm.id);
  }, [clientForm.id, refetchUsers]);

  const editForm = useCallback(() => {
    navigate(`/clients/${clientForm?.clientId}/forms/${clientForm?.id}?edit`);
  }, [clientForm?.clientId, clientForm?.id, navigate]);

  const canEditForm = useMemo(() => [ClientFormStatus.InProgress, ClientFormStatus.NotStarted].indexOf(formStatus) > -1, [formStatus]);

  const { triggerPdfDownload, pdfDownloadModal } = useDownloadPdf(clientForm.type === FormType.Document ? 'pdf' : undefined);

  const canExportClientForm = useMemo(() => {
    return hasPermission(Roles.TeamMember);
  }, [hasPermission]);

  const showDownloadEvidenceReport = useMemo(
    () => clientForm.form.requiresApprovalSignature || clientForm.form.requiresValidationSignature,
    [clientForm.form.requiresApprovalSignature, clientForm.form.requiresValidationSignature],
  );

  const canDownloadEvidenceReport = useMemo(() => {
    return (
      clientForm.status === ClientFormStatus.Completed && (clientForm.form.requiresApprovalSignature || clientForm.form.requiresValidationSignature)
    );
  }, [clientForm.form.requiresApprovalSignature, clientForm.form.requiresValidationSignature, clientForm.status]);

  const canDownloadUnControlledCopy = useMemo(() => {
    if (canExportClientForm && clientForm.type === FormType.Document) {
      return true;
    }
    // If the user is part of the distribution, they can download the uncontrolled copy
    if (
      !canExportClientForm &&
      clientForm.type === FormType.Document &&
      !!clientForm.distribution &&
      !!clientForm.distribution.members.find((x) => x.memberId === currentUser?.id)
    ) {
      return true;
    }
    return false;
  }, [canExportClientForm, clientForm.distribution, clientForm.type, currentUser?.id]);

  const onDownloadEvidenceReport = useCallback(() => {
    DataExportService.downloadEvidenceReport(clientForm.id).then((content) => {
      const url = URL.createObjectURL(content);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.download = `${t('form:evidence-report', { document: prefix + title })}.pdf`;
      downloadLink.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  }, [clientForm.id, prefix, t, title]);

  const onDownloadUncontrolledCopy = useCallback(() => {
    DataExportService.downloadUncontrolledCopy(clientForm.id).then((content) => {
      const url = URL.createObjectURL(content);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.download = `${t('form:uncontrolled-copy', { document: prefix + title })}.pdf`;
      downloadLink.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  }, [clientForm.id, prefix, t, title]);

  const contextItems = useMemo<ContextMenuItem[]>(() => {
    return [
      {
        title: t('form:context.preview'),
        icon: <EyeIcon className="h-5 w-5" />,
        onClick: () => {
          navigate(`/clients/${clientForm.clientId}/forms/${clientForm.id}/preview#details`);
        },
        hide: !editMode || expandedView,
      },
      {
        title: t('form:context.edit'),
        icon: <EditIcon className="h-5 w-5" />,
        onClick: () => editForm(),
        hide: editMode || expandedView || readOnly || !canEditForm,
      },
      {
        title: t('form:context.replace'),
        icon: <RefreshIcon className="h-5 w-5" />,
        onClick: () => setShowReplaceDocumentModal(true),
        hide:
          !canEditForm ||
          !clientForm.form.isSystem ||
          !hasPermission(Roles.TeamMember) ||
          clientForm.status === ClientFormStatus.Completed ||
          readOnly,
      },
      {
        title: t('form:context.share'),
        icon: <ShareIcon className="h-4 w-4" />,
        onClick: () => setShowFormShareModal(true),
        hide: clientForm.type !== FormType.Asset || expandedView || hideMainFormActions || readOnly,
      },
      {
        title: canMaintainDistribution ? t('form:context.manage-distribution') : t('form:context.view-distribution'),
        icon: <DistributeHandsIcon className="h-5 w-5" />,
        onClick: () => setShowDistributionModal('manage'),
        hide: !canViewDistribution || readOnly,
      },
      {
        title: t('form:context.create-distribution'),
        icon: <DistributeHandsIcon className="h-5 w-5" />,
        onClick: () => setShowDistributionModal('create'),
        hide: !canCreateDistribution || clientForm.type !== FormType.Document || readOnly,
      },
      {
        title: t('form:context.send-uncontrolled-copy'),
        icon: <MailIcon className="h-5 w-5" />,
        onClick: () => setShowUncontrolledCopyModal(true),
        hide: !canSendUncontrolledCopy || clientForm.type !== FormType.Document || readOnly,
      },
      {
        title: clientForm.type === FormType.Document ? t('form:context.download-document') : t('form:context.download-activity'),
        icon: <DownloadIcon className="h-5 w-5" />,
        onClick: () =>
          triggerPdfDownload(
            clientForm.id,
            clientForm.type === FormType.Document ? FormUtils.getDocumentTitle(clientForm as DocumentResponse) : FormUtils.getFormTitle(clientForm),
          ),
        hide: !canExportClientForm || expandedView,
      },
      {
        title: t('form:context.download-uncontrolled-copy'),
        icon: <DownloadIcon className="h-5 w-5" />,
        onClick: onDownloadUncontrolledCopy,
        hide: !canDownloadUnControlledCopy || expandedView,
      },
      {
        title: t('form:context.download-evidence'),
        icon: <DownloadIcon className="h-5 w-5" />,
        onClick: onDownloadEvidenceReport,
        hide: !canExportClientForm || expandedView || !showDownloadEvidenceReport,
        disabled: !canDownloadEvidenceReport,
        disabledTooltip: t('form:evidence-report-not-available'),
      },
      {
        title: t(clientForm.type === FormType.Document ? 'form:context.archive-documents' : 'form:context.archive'),
        icon: <DeleteIcon className="h-5 w-5" />,
        onClick: () => setShowArchiveConfirmModal(true),
        hide:
          !hasPermission(Roles.Management) || hideMainFormActions || (clientForm.isShared && clientForm.clientId !== currentClient?.id) || readOnly,
      },
    ];
  }, [
    canCreateDistribution,
    canDownloadEvidenceReport,
    canDownloadUnControlledCopy,
    canEditForm,
    canExportClientForm,
    canMaintainDistribution,
    canSendUncontrolledCopy,
    canViewDistribution,
    clientForm,
    currentClient?.id,
    editForm,
    editMode,
    expandedView,
    hasPermission,
    hideMainFormActions,
    navigate,
    onDownloadEvidenceReport,
    onDownloadUncontrolledCopy,
    readOnly,
    showDownloadEvidenceReport,
    t,
    triggerPdfDownload,
  ]);

  const updateExpanedView = useCallback(() => {
    setExpandedView(window.innerWidth > 1400 || readOnly);
  }, [readOnly]);

  useEffect(() => {
    const handler = () => updateExpanedView();
    handler();
    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [updateExpanedView]);

  const archiveForm = useCallback(() => {
    ClientFormService.archiveForm(clientForm.id).then(() => {
      if (clientForm.type === FormType.Document) {
        navigate(`/clients/${clientForm.clientId}/documents`);
      } else {
        navigate(`/clients/${clientForm.clientId}/modules/${clientForm.clientModuleId}`);
      }
    });
  }, [clientForm.clientId, clientForm.clientModuleId, clientForm.id, clientForm.type, navigate]);

  const riskBadge = useMemo(() => {
    if (!clientForm.totalRiskScore) {
      return null;
    }

    const text = RiskUtils.textFor(t, clientForm.totalRiskScore);
    const textColor = RiskUtils.textColorFor(clientForm.totalRiskScore);
    const bgColor = RiskUtils.bgColorFor(clientForm.totalRiskScore);

    return <Badge text={text} textSize={'text-dpm-16'} textClass={textColor} backgroundClass={`${bgColor} bg-opacity-10`} />;
  }, [clientForm.totalRiskScore, t]);

  const subtitleChange = useCallback(
    (newValue: string) => {
      ClientFormService.updateForm(
        clientForm.id,
        clientForm.accessType,
        clientForm.dueDateUtc === null ? null : new Date(clientForm.dueDateUtc),
        clientForm.effectiveDateUtc === null ? null : new Date(clientForm.effectiveDateUtc),
        newValue,
        clientForm.clientModuleId,
        clientForm.clientModuleSectionId,
      );
      onRename(newValue);
    },
    [
      clientForm.accessType,
      clientForm.clientModuleId,
      clientForm.clientModuleSectionId,
      clientForm.dueDateUtc,
      clientForm.effectiveDateUtc,
      clientForm.id,
      onRename,
    ],
  );

  const accessTypeChange = useCallback(
    (newValue: Access) => {
      ClientFormService.updateForm(
        clientForm.id,
        newValue,
        clientForm.dueDateUtc === null ? null : new Date(clientForm.dueDateUtc),
        clientForm.effectiveDateUtc === null ? null : new Date(clientForm.effectiveDateUtc),
        clientForm.subtitle,
        clientForm.clientModuleId,
        clientForm.clientModuleSectionId,
      );
      onAccessTypeChange && onAccessTypeChange(newValue);
    },
    [
      clientForm.clientModuleId,
      clientForm.clientModuleSectionId,
      clientForm.dueDateUtc,
      clientForm.effectiveDateUtc,
      clientForm.id,
      clientForm.subtitle,
      onAccessTypeChange,
    ],
  );

  const onCloseReplaceWizard = useCallback(() => {
    setShowReplaceDocumentModal(false);
    setActiveReplaceWizardStep(replaceDocumentWizardSteps[0]);
  }, []);

  const onReplaced = useCallback(() => {
    onFileReplaced && onFileReplaced();
    onCloseReplaceWizard();
  }, [onCloseReplaceWizard, onFileReplaced]);

  const formTypeText = useFormTypeText(clientForm.type, t);

  const versionState = useRecoilValue(currentFormVersionStateAtom);

  const effectiveDateErrorState = useMemo(() => {
    if (!clientForm.effectiveDateUtc) return null;

    const today = DateUtils.resetTimeComponent(DateUtils.now);
    const effDate = DateUtils.resetTimeComponent(new Date(clientForm.effectiveDateUtc));
    if (effDate.getTime() >= today.getTime()) return null;

    if (clientForm.status === ClientFormStatus.SubmittedForApproval) {
      return {
        type: 'approval' as const,
        text: (
          <Trans
            t={t}
            i18nKey={'form:effective-date.effect-date-in-past-approval'}
            components={{ p: <p className="my-1" />, Gray: <div className="text-gray-2 mt-4" /> }}
          />
        ),
      };
    } else if (clientForm.status === ClientFormStatus.SubmittedForValidation) {
      return {
        type: 'validation' as const,
        text: (
          <Trans
            t={t}
            i18nKey={'form:effective-date.effect-date-in-past-review'}
            components={{ p: <p className="my-1" />, Gray: <div className="text-gray-2 mt-4" /> }}
          />
        ),
      };
    }

    return {
      type: 'submit' as const,
      text: (
        <Trans
          t={t}
          i18nKey={'form:effective-date.effect-date-in-past-submit'}
          components={{ p: <p className="my-1" />, Gray: <div className="text-gray-2 mt-4" /> }}
        />
      ),
    };
  }, [clientForm.effectiveDateUtc, clientForm.status, t]);

  const statusText = useStatusText(clientForm);

  const showAcknowledgeButton = useMemo(() => {
    const distribution = (clientForm as DocumentResponse).distribution;
    if (!distribution) return false;

    if (distribution.status !== DistributionStatus.Active) return false;

    const member = distribution.members.find((x) => x.memberId === currentUser?.id);
    if (!member) return false;

    return ![DistributionMemberStatus.Acknowledged, DistributionMemberStatus.Signed].includes(member.status);
  }, [clientForm, currentUser?.id]);

  const predefinedTitle = useMemo(
    () => LanguageUtils.getTranslation('predefinedTitle', clientForm.form.translations || {}),
    [clientForm.form.translations],
  );

  return (
    <div className="flex min-w-full max-w-0 items-center justify-between gap-4 border-b px-5 pb-4 pt-2">
      <div className="w-full">
        <InlineEditor
          value={title}
          onChange={subtitleChange}
          size="text-dpm-32"
          maxLength={100}
          suffix={
            <div className="flex items-center">
              v{clientForm.majorVersion}{' '}
              {!clientForm.archivedUtc ? (
                <VersionStateLabel state={versionState} />
              ) : (
                <span className="text-dpm-14 text-status-tag-1 ml-2 font-medium">{statusText}</span>
              )}
            </div>
          }
          prefix={prefix}
          disabled={readOnly || !canEditForm || !!predefinedTitle}
        />
        <span className="text-dpm-16 text-color-on-first-primary-1">
          <Tooltip text={subtitle} truncatedTextMode>
            {(tooltip) => (
              <div className="min-w-full max-w-0 truncate" {...tooltip}>
                {subtitle}
              </div>
            )}
          </Tooltip>
        </span>
      </div>

      <div className="flex flex-shrink-0 items-center justify-end gap-4">
        <div className="piped-items flex items-center justify-end gap-4">
          {(clientForm.periodicReviewChecklist?.length ?? 0) > 0 &&
            (clientForm.periodicReviewStatus === PeriodicReviewStatus.UnderReview ||
              clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewOverdue) && (
              <div className="flex flex-shrink-0 items-center gap-2">
                <Button type={ButtonType.TERTIARY} onClick={() => setShowChecklist(true)}>
                  <Button.Slot name="Icon">
                    <MultiCheckboxIcon className="h-6 w-6" />
                  </Button.Slot>
                  <span className="flex items-center text-black">{t('common:form-header.buttons.checklist')}</span>
                  &nbsp;
                  <span className="text-gray-2 flex items-center">
                    ({clientForm.periodicReviewChecklist?.filter((x) => x.checked).length}/{clientForm.periodicReviewChecklist?.length})
                  </span>
                </Button>
              </div>
            )}
          {!hideMainFormActions && (
            <>
              <div className="flex flex-shrink-0 flex-col">
                <span className="text-dpm-14 text-gray-2 font-medium">{t('form:header-titles.status')}</span>
                <div className="text-dpm-14 flex justify-between gap-4">
                  {formStatus === ClientFormStatus.InProgress && !clientForm.form.isSystem && (
                    <>
                      <div>{t(formStatusKeys[formStatus])}</div>
                      <div>
                        {formProgress.totalAnswers} / {formProgress.totalRequired}
                      </div>
                    </>
                  )}
                  {(formStatus !== ClientFormStatus.InProgress || clientForm.form.isSystem) && (
                    <FormStatusTag activity={clientForm} preserveSpace={false} align="left" />
                  )}
                </div>
                {formStatus === ClientFormStatus.InProgress && !clientForm.form.isSystem && (
                  <ProgressBar progress={formProgress.totalRequired === 0 ? 0 : formProgress.totalAnswers / formProgress.totalRequired} />
                )}
              </div>

              {!(clientForm.status === ClientFormStatus.NotStarted || clientForm.status === ClientFormStatus.InProgress) && (
                <>
                  {clientForm.totalRiskScore !== undefined && clientForm.totalRiskScore > RiskRating.NO_RISK && (
                    <div>
                      <span className="text-dpm-14 text-gray-2 font-medium">{t('form:header-titles.risk')}</span>
                      <div>{riskBadge}</div>
                    </div>
                  )}
                </>
              )}
              {!readOnly && (
                <div className="flex-shrink-0">
                  <span className="text-dpm-14 text-gray-2 font-medium">{t('form:header-titles.due-date')}</span>
                  <div className="-ml-2">
                    <DueDatePicker
                      clientFormStatus={clientForm.status}
                      value={clientForm.dueDateUtc ? new Date(clientForm.dueDateUtc) : null}
                      onChange={onFormDueDateChange}
                      notBefore={startDate ?? undefined}
                      disabled={
                        !canEditForm ||
                        (!!(clientForm.form.requiresApproval || clientForm.form.requiresValidation) &&
                          !hasFormRole(
                            [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
                            currentFormUser,
                          )) ||
                        !hasPermission([Roles.TeamMember])
                      }
                    />
                  </div>
                </div>
              )}

              {!readOnly && clientForm.type === FormType.Document && (
                <div className="flex-shrink-0">
                  <span className="text-dpm-14 text-gray-2 font-medium">{t('form:header-titles.effective-date')}</span>
                  <div className="-ml-2">
                    <EffectiveDatePicker
                      value={clientForm.effectiveDateUtc ? new Date(clientForm.effectiveDateUtc) : null}
                      onChange={onEffectiveDateChanged}
                      errorState={!!effectiveDateErrorState}
                      disabled={
                        (!!effectiveDateErrorState && effectiveDateErrorState.type !== 'submit') ||
                        !canEditForm ||
                        (!!(clientForm.form.requiresApproval || clientForm.form.requiresValidation) &&
                          !hasFormRole(
                            [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
                            currentFormUser,
                          )) ||
                        !hasPermission([Roles.TeamMember])
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <FormInfoModal
            form={clientForm}
            clientFormUsers={formUsers}
            open={showFormInfoModal}
            onClose={() => setShowFormInfoModal(false)}
            onChecklistChanged={onChecklistChanged}
            associations={associations}
          />
          <FormShareModal clientForm={clientForm} onClose={() => setShowFormShareModal(false)} open={showFormShareModal} />
          <ModalContext.Provider
            value={{
              open: showReplaceDocumentModal,
              onClose: onCloseReplaceWizard,
              modalWidth: 'lg:w-2/5 w-1/2',
            }}
          >
            <ReplaceDocumentWizard
              key={(clientForm as DocumentResponse).fileId}
              onCancel={onCloseReplaceWizard}
              document={clientForm as DocumentResponse}
              onReplaced={onReplaced}
              activeStep={activeReplaceWizardStep}
            />
          </ModalContext.Provider>

          {!hideMainFormActions && (hasPermission(Roles.Employee) || !hasPermission(Roles.ExternalAuditor)) && (
            <div>
              <span className="text-dpm-14 text-gray-2 font-medium">{t('form:header-titles.members')}</span>
              <FormOwnership
                users={formUsers}
                associatedId={clientForm.id}
                onCurrentUserRoleChange={onCurrentUserRoleChange}
                onUsersChange={onFormUsersChange}
                requiresApproval={!!clientForm.form.requiresApproval}
                requiresValidation={!!clientForm.form.requiresValidation}
                requiresOwner={
                  clientForm.type === FormType.Document || (formUsers.length > 0 && formUsers.some((x) => x.role === ClientFormUserRole.Owner))
                }
                displayLimit={3}
                viewOnly={
                  formStatus === ClientFormStatus.Completed ||
                  (!hasPermission([Roles.Employee]) &&
                    !hasFormRole(
                      [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
                      currentFormUser,
                    )) ||
                  readOnly
                }
                accessType={clientForm.type === FormType.Document ? clientForm.accessType : undefined}
                onAccesChange={accessTypeChange}
                size={OwnershipDisplayType.Tiny}
              />
            </div>
          )}

          <div className="flex-shrink-0">
            <div className="flex items-center gap-2">
              {expandedView && (hasPermission(Roles.Management) || !onlyHasPermission(Roles.ExternalAuditor)) && (
                <>
                  {editMode && (
                    <Tooltip text={<>{t('form:context.preview')}</>}>
                      {(tooltip) => (
                        <div
                          {...tooltip}
                          {...mouseAndKeyboardCallbackProps(() => navigate(`/clients/${clientForm.clientId}/forms/${clientForm.id}/preview#details`))}
                          data-cy="preview-icon"
                          className="cursor-pointer"
                        >
                          <EyeIcon className="h-6 w-6 text-black" />
                        </div>
                      )}
                    </Tooltip>
                  )}
                  {!canExportClientForm ? null : showDownloadEvidenceReport || canDownloadUnControlledCopy ? (
                    <ContextMenu
                      type="icon"
                      items={[
                        {
                          title: clientForm.type === FormType.Document ? t('form:context.download-document') : t('form:context.download-activity'),
                          onClick: () =>
                            triggerPdfDownload(
                              clientForm.id,
                              clientForm.type === FormType.Document
                                ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                                : FormUtils.getFormTitle(clientForm),
                            ),
                        },
                        {
                          title: t('form:context.download-uncontrolled-copy'),
                          onClick: onDownloadUncontrolledCopy,
                          hide: !canDownloadUnControlledCopy,
                        },
                        {
                          title: t('form:context.download-evidence'),
                          onClick: onDownloadEvidenceReport,
                          disabled: !canDownloadEvidenceReport,
                          hide: !showDownloadEvidenceReport,
                          disabledTooltip: t('form:evidence-report-not-available'),
                        },
                      ]}
                    >
                      {(triggerRef) => (
                        <Tooltip text={<>{t('form:context.download')}</>}>
                          {(tooltipRef) => (
                            <div ref={triggerRef}>
                              <div {...tooltipRef}>
                                <DownloadIcon className="h-6 w-6 cursor-pointer text-black" />
                              </div>
                            </div>
                          )}
                        </Tooltip>
                      )}
                    </ContextMenu>
                  ) : (
                    <Tooltip text={<>{t('form:context.download')}</>}>
                      {(tooltipRef) => (
                        <div {...tooltipRef}>
                          <DownloadIcon
                            className="h-6 w-6 text-black"
                            onClick={() =>
                              triggerPdfDownload(
                                clientForm.id,
                                clientForm.type === FormType.Document
                                  ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                                  : FormUtils.getFormTitle(clientForm),
                              )
                            }
                          />
                        </div>
                      )}
                    </Tooltip>
                  )}

                  {!canExportClientForm && canDownloadUnControlledCopy && (
                    <Tooltip text={<>{t('form:context.download-uncontrolled-copy')}</>}>
                      {(tooltipRef) => (
                        <div {...tooltipRef}>
                          <DownloadIcon className="h-6 w-6 text-black" onClick={onDownloadUncontrolledCopy} />
                        </div>
                      )}
                    </Tooltip>
                  )}

                  {!editMode && canEditForm && !readOnly && (
                    <Tooltip text={<>{t('form:context.edit')}</>}>
                      {(tooltip) => (
                        <div {...tooltip} {...mouseAndKeyboardCallbackProps(editForm)} data-cy="edit-icon" className="cursor-pointer">
                          <EditIcon className="h-6 w-6 text-black" />
                        </div>
                      )}
                    </Tooltip>
                  )}
                  {!hideMainFormActions && clientForm.type === FormType.Asset && !readOnly && (
                    <Tooltip text={<>{t('form:context.share')}</>}>
                      {(tooltip) => (
                        <div {...tooltip}>
                          <ShareIcon className="h-4 w-4 text-black" onClick={() => setShowFormShareModal(true)} />
                        </div>
                      )}
                    </Tooltip>
                  )}
                </>
              )}
              {!readOnly &&
                (hasPermission(Roles.Management) || !hasPermission(Roles.ExternalAuditor)) &&
                !(expandedView && hideMainFormActions) &&
                contextItems.some((x) => !x.hide) && <ContextMenu items={contextItems} />}
            </div>
          </div>
        </div>
        {showAcknowledgeButton && <AcknowledgeButton disabled={!scrolledToBottom} document={clientForm as DocumentResponse} />}
        {!readOnly && !showAcknowledgeButton && (
          <FormSubmit
            clientForm={clientForm}
            formStatus={clientForm.status}
            formUsers={formUsers}
            onStatusChange={onStatusChanged}
            onUserAdded={() => refetchUsers(clientForm.id)}
            allSteps={allSteps}
            allStepInfos={allStepInfos}
            effectiveDateErrorState={effectiveDateErrorState}
          />
        )}
      </div>

      <ModalContext.Provider value={{ open: showArchiveConfirmModal, onClose: () => setShowArchiveConfirmModal(false), modalWidth: 'w-2/5' }}>
        <ConfirmationModal
          title={
            clientForm &&
            t(`${clientForm.type === FormType.Document ? 'documents' : 'form'}:archive-modal.heading`, {
              name: FormUtils.getFormTitle(clientForm),
            })
          }
          description={
            clientForm?.isShared
              ? t(`${clientForm.type === FormType.Document ? 'documents' : 'form'}:archive-modal.body-shared`)
              : t(`${clientForm.type === FormType.Document ? 'documents' : 'form'}:archive-modal.body`)
          }
          cancelText={t(`${clientForm.type === FormType.Document ? 'documents' : 'form'}:archive-modal.buttons.cancel`)}
          confirmText={t(`${clientForm.type === FormType.Document ? 'documents' : 'form'}:archive-modal.buttons.archive`)}
          onCancel={() => setShowArchiveConfirmModal(false)}
          onConfirm={archiveForm}
          alt
        />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showChecklist, modalWidth: 'w-2/5', onClose: () => setShowChecklist(false) }}>
        <StandardModal
          title={t('common:form-header.modals.checklist.heading')}
          subTitle={t('common:form-header.modals.checklist.subheading', {
            type: formTypeText,
          })}
        >
          {clientForm.periodicReviewChecklist && (
            <PeriodicChecklistViewer
              clientFormId={clientForm.id}
              checklist={clientForm.periodicReviewChecklist}
              onChecklistChanged={onChecklistChanged}
              disabled={
                clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewCompleted ||
                clientForm.periodicReviewStatus === PeriodicReviewStatus.ReviewArchived
              }
            />
          )}
        </StandardModal>
      </ModalContext.Provider>

      {pdfDownloadModal}

      {clientForm && clientForm.type === FormType.Document && (
        <>
          {canCreateDistribution && (
            <DistributionWizard
              open={showDistributionModal === 'create'}
              onClose={() => setShowDistributionModal(false)}
              startingStep="start"
              clientFormId={clientForm.id}
              clientFormVersion={clientForm.majorVersion}
              clientFormSubtitle={
                StringUtils.makePrefixWithNumber(
                  (clientForm as DocumentResponse).prefix,
                  (clientForm as DocumentResponse).documentNumber,
                  (clientForm as DocumentResponse).templateModuleTranslations,
                ) +
                '-' +
                clientForm.subtitle
              }
            />
          )}

          {(canViewDistribution || canMaintainDistribution) && (
            <DistributionManageModal
              open={showDistributionModal === 'manage'}
              onClose={() => setShowDistributionModal(false)}
              distribution={(clientForm as DocumentResponse).distribution}
              clientFormSubtitle={
                StringUtils.makePrefixWithNumber(
                  (clientForm as DocumentResponse).prefix,
                  (clientForm as DocumentResponse).documentNumber,
                  (clientForm as DocumentResponse).templateModuleTranslations,
                ) +
                '-' +
                clientForm.subtitle
              }
            />
          )}

          {canSendUncontrolledCopy && (
            <UncontrolledCopyModal
              open={showUncontrolledCopyModal}
              onClose={() => setShowUncontrolledCopyModal(false)}
              clientFormId={clientForm.id}
              clientFormVersion={clientForm.majorVersion}
              clientFormSubtitle={
                StringUtils.makePrefixWithNumber(
                  (clientForm as DocumentResponse).prefix,
                  (clientForm as DocumentResponse).documentNumber,
                  (clientForm as DocumentResponse).templateModuleTranslations,
                ) +
                '-' +
                clientForm.subtitle
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default FormHeader;
